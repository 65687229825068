import {FullStory, init as initFS, isInitialized } from "@fullstory/browser";
import {FS_DEFAULT_CONFIG} from "../config/fullstoryConfig"
export const initFullstory = (fsOptions, userIdentity) => {
    try {

        const options = { ...{ orgId: FS_DEFAULT_CONFIG.ORG_ID }, ...fsOptions }

        initFS(options);

        if(isInitialized) {
            console.log("FS initialized");
            FullStory('setIdentity', userIdentity);
        } else {
            console.log("FS not initialized");
        }
    } catch(err) {
        console.log(err);
    }

    
}
import "./index.scss"
import { iconNetwork } from "../assets/iconNetwork";
import { CONNECTIVITY_CONFIG } from "../config/connectivityTest";
export const initSpeedtest = function (config) {
    console.log("IGREConnectivity:: Initialized");
    let speed = [];
    let ua = window.navigator.userAgent;

    let isAndroidChrome = ua.includes("Android") && ua.includes("Chrome") && !ua.match(/EdgA/i);

    let iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    let webkit = !!ua.match(/WebKit/i);
    let iOSSafari = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/FxiOS/i) && !ua.match(/EdgiOS/i);

    if ((iOSSafari || isAndroidChrome)) {
        downloadImage(config, speed);
    }
}



export const downloadImage = function (config, speed) {
    if (config && speed) {
        let seq = speed.length;
        let imgName = "speedtest" + (seq % 5) + ".png";
        let imageAddr = CONNECTIVITY_CONFIG.CONNECTIVITY_URL_BASEPATH + imgName;
        let downloadSize = 990872; // bytes    

        let startTime, endTime
        let download = new Image()
        download.onload = function () {
            endTime = new Date().getTime()
            calculateSpeed(config, speed)
        }

        startTime = new Date().getTime()
        let cacheBuster = "?nnn=" + startTime
        download.src = imageAddr + cacheBuster
        const calculateSpeed = function (config, speed) {
            let duration = (endTime - startTime) / 1000
            let bitsLoaded = downloadSize * 8
            let speedBps = (bitsLoaded / duration).toFixed(2)
            let speedKbps = (speedBps / 1024).toFixed(2)
            let speedMbps = (speedKbps / 1024).toFixed(2)
            window.postMessage(
                {
                    IGRE_MESSAGE_ID: "IGRE_SPEED_TEST",
                    config: config,
                    speed: speed,
                    Speed_MBPS: speedMbps,
                    Success: true,
                },
                "*"
            )
        }
    }
}

window.addEventListener("message", (event) => {
    if (event?.data?.IGRE_MESSAGE_ID === "IGRE_SPEED_TEST") {
        let data = event.data
        let speed = data.speed || [];
        speed.push(data.Speed_MBPS)
        if (speed.length >= CONNECTIVITY_CONFIG.CONNECTIVITY_TEST_COUNT) {
            const maxSpeed = Math.max(...speed);
            console.log("igre-speedtest", {"maxspeed": maxSpeed, "speedLimit": data.config.reqSpeedLimit || CONNECTIVITY_CONFIG.DEFAULT_SPEED_LIMIT});
            if (data.config.reqSpeedLimit) {
                showProgressBanner(maxSpeed, data.config.reqSpeedLimit)
            } else {
                showProgressBanner(maxSpeed, CONNECTIVITY_CONFIG.DEFAULT_SPEED_LIMIT)
            }
        } else {
            setTimeout(function () {
                downloadImage(data.config, speed);
            }, CONNECTIVITY_CONFIG.CONNECTIVITY_TEST_DELAY)
        }
    }
})

const showProgressBanner = function (maxSpeed, speedLimit) {
    let banner = document.getElementById("igre-connectivity-banner");
    if (maxSpeed < speedLimit) {
        if (!banner) {
            banner = document.createElement('div');
            banner.id = 'igre-connectivity-banner';
            banner.innerHTML = `<div>${iconNetwork}<span>Weak signal. Performance may be limited.</span></div>`;
            let contentWrapElement =  document.getElementsByClassName('content-wrap')[0];
            if(contentWrapElement) {
                contentWrapElement.insertBefore(banner, contentWrapElement.firstChild);
                //contentWrapElement.appendChild(banner);
            }
        }
        banner.style.display = "block";
        //document.getElementsByClassName('content-wrap')[0].style.marginTop = "36px";
        setTimeout(function () {
            document.getElementById("igre-connectivity-banner").style.display = "none";
            //document.getElementsByClassName('content-wrap')[0].style.marginTop = "0px";
        }, CONNECTIVITY_CONFIG.BANNNER_TIMEOUT)
    } else if (banner) {
        banner.style.display = "none"
    }
}

import { sideItemClass } from "../constants"
import iconArrowLeft from "../assets/iconArrowLeft"

import "./index.scss"

const linkItem = ({ url, text, display }) => `
  <a class="sidebar__list-link" href="${url}">
    <div class="sidebar__link-icon-wrap">
      <div class="sidebar__link-icon ${display}" > </div>
    </div>
    <span class="sidebar__link-name">${text}</span>
  </a>
`

const expandableItem = ({ text, display, subItems }) => `
  <div class="sidebar__list-link">
    <aside class="sidebar__caret"></aside>
    <div class="sidebar__link-icon-wrap">
      <div class="sidebar__link-icon ${display}"></div>
    </div>
    <span class="sidebar__link-name">${text}</span>
    <ul class="subnav">
      <div class="subnav__mobile-header-wrap">
        ${iconArrowLeft()}
        <h4 class="subnav__mobile-header">${text}</h4>
      </div>
      <span class="subnav__caret"></span>
      ${subItems
        .map(
          sub =>
            `<li class="subnav__list-item">
            <a class="subnav__list-link" href="${sub.url}">${sub.text}</a>
          </li>`
        )
        .join("")}
    </ul>
  </div>
`

// Each sidebar item can be rendered as a link or a div which expands to show subItems.
export default item => {
  const element = document.querySelector('[data-highlightmenu]');
  const dataAppname = element ? element.getAttribute('data-highlightmenu') : "";

  function isActive(){
    return (item.text == dataAppname)
  }
  

  const activeClass = isActive() ? ` ${sideItemClass}--active` : ""

  const sideBarId = item.text ? "sidebar_" + (item.text).replace(/ /g, '').toLowerCase() + "_id" : "";

  return `
    <li class="${`${sideItemClass}${activeClass}`}" id="${sideBarId}" >
      ${item.url ? linkItem(item) : expandableItem(item)}
    </li>
  `
}

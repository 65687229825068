import iconArrowLeft from "../assets/iconArrowLeft"
import iconCaretDown from "../assets/iconCaretDown"
import iconClose from "../assets/iconClose"
import iconHamburger from "../assets/iconHamburger"
import iconResourcecenter from "../assets/iconResourcecenter"
import iconMeatBall from "../assets/iconMeatBall"
import { addClass, toggleClass, removeClass, hasClass } from "../utils"

import "./index.scss"

export const dropdownItemsClass = "topnav-dropdown__items"
export const topnavDropdownClass = "topnav-dropdown"

export const meatballDropdownItemsClass = "meatball-dropdown__items"
export const meatballDropdownClass = "meatball-dropdown"

const dropdownTriggerClass = "topnav-dropdown__title"

export const topNavEl = (topItems, user) => {
  const nav = document.createElement("nav")
  
  const meatballDropdownTriggerClass = "meatball-dropdown__title"

  const element = document.querySelector('[data-highlightmenu]');
  const dataAppname = element ? element.getAttribute('data-highlightmenu') : "<div class='igniteRE-logo-mobile'></div>";

  addClass(nav, "topnav")
  nav.innerHTML = `
    <article class="topnav__left">
      ${iconHamburger()}
      ${iconClose()}
    </article>
    <article class="topnav__middle">
    <div class="igniteRE-logo"></div>
    <div class="igniteRE-logo-mobile--text">`+dataAppname+`</div>
    </article>
    <article class="topnav__right">
      <ul class="topnav__list">
        ${topItems
          .filter(item => !item.mobileOnly)
          .map(
            ({ text, display, url }) => `
              <li class="topnav__list-item">
                <a class="topnav__link" href="${url}">
                  <div class="${display}"> </div>
                  <span class="topnav__tooltip">${text}</span>
                </a>
              </li>
            `
          )
          .join("")}

        <li class=${topnavDropdownClass}>
          ${user && user.links ?
            `<span class="${dropdownTriggerClass}">
              <p class="topnav-dropdown__employee">${user.first}</p>
              ${iconCaretDown()}
            </span>
            <div class="${dropdownItemsClass}">
            
              ${user.links.filter(item => !item.mobileOnly)
                .map(
                  (item) => 

                    (item.separator) ? 
                    `<a href="${item.url}" class="topnav-dropdown__item divider" id="${getTopNavId(item.text)}">
                      <span>${item.text}</span>
                    </a>` : 
                    `<a href="${item.url}" class="topnav-dropdown__item" id="${getTopNavId(item.text)}">
                      <span>${item.text}</span>
                    </a>`
                )
                .join("")}
                
            </div>`
          : ""}
        </li>
        <li class="topnav__list-item">
          <div class="topnav-resourcecenter">
                ${iconResourcecenter()}
          </div>
        </li>
      </ul>
    </article>`
    const capLinks = user && user.links ? user.links.filter(item => item.mobileOnly) : "";
    if(capLinks.length > 0){
      nav.innerHTML += `<article class="topnav__right__meatball">
      <ul class="meatball__list">
        <li class=${meatballDropdownClass}>
          <span class="${meatballDropdownTriggerClass}">
            ${iconMeatBall()}
          </span>
          <div class="${meatballDropdownItemsClass}">
          
            ${user.links.filter(item => item.mobileOnly)
              .map(
                (item) => 
                (item.separator) ? 
                `<a href="${item.url}" class="meatball-dropdown__item divider">
                  <span>${item.text}</span>
                </a>` : 
                `<a href="${item.url}" class="meatball-dropdown__item">
                  <span>${item.text}</span>
                </a>`
              )
              .join("")}
          </div>
        </li>
      </ul>
    </article>`
      const meatballTrigger = nav.getElementsByClassName(meatballDropdownTriggerClass)[0]
      const meatballDropdown = nav.getElementsByClassName(meatballDropdownClass)[0]
      
      
      meatballTrigger.onclick = () => {
        toggleClass(meatballDropdown, `${meatballDropdownClass}--open`)
        const hamburger = document.getElementsByClassName('topnav__icon-hamburger')[0]
        const close = document.getElementsByClassName("topnav__icon-close")[0]
        const sidebar = document.getElementsByClassName("sidebar")[0]
        const backdrop = document.getElementsByClassName("content-wrap")[0]
        
        if (hasClass(hamburger, 'is-hidden')) {
          toggleClass(hamburger, "is-hidden")
        }
        if (hasClass(close, 'is-visible')) {
          toggleClass(close, "is-visible")
        }
        if (hasClass(sidebar, 'is-visible')) {
          toggleClass(sidebar, "is-visible")
        }
        if (hasClass(backdrop, 'backdrop-show')) {
          toggleClass(backdrop, "backdrop-show")
        }
      }
    }
    


  const trigger = nav.getElementsByClassName(dropdownTriggerClass)[0]
  const dropdown = nav.getElementsByClassName(topnavDropdownClass)[0]
  if(trigger) {
    trigger.onclick = () => {
      toggleClass(dropdown, `${topnavDropdownClass}--open`)
    }
  }

  return nav
}

const getTopNavId = (text) => {
  return text ? "topnav_" + (text).replace(/[^A-Za-z0-9]+/g, '').toLowerCase() + "_id" : "";
}

export const renderUserItems = (user) => {
  const topNavDropDownElement = document.getElementsByClassName(topnavDropdownClass)[0];
  if(topNavDropDownElement && user) {
    topNavDropDownElement.innerHTML = `
      <span class="${dropdownTriggerClass}">
        <p class="topnav-dropdown__employee">${user.first}</p>
        ${iconCaretDown()}
      </span>
      <div class="${dropdownItemsClass}">
      
        ${user.links ? user.links.filter(item => !item.mobileOnly)
          .map(
            (item) => 

              (item.separator) ? 
              `<a href="${item.url}" class="topnav-dropdown__item divider" id="${getTopNavId(item.text)}">
                <span>${item.text}</span>
              </a>` : 
              `<a href="${item.url}" class="topnav-dropdown__item" id="${getTopNavId(item.text)}">
                <span>${item.text}</span>
              </a>`
          )
          .join("") : ""}
        </div>`

      const trigger = document.getElementsByClassName(dropdownTriggerClass)[0]
      const dropdown = document.getElementsByClassName(topnavDropdownClass)[0]
      if(trigger) {
        trigger.onclick = () => {
          toggleClass(dropdown, `${topnavDropdownClass}--open`)
        }
      }
  }

}
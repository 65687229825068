import "./index.scss"
//import { toggleClass, addClass, removeClass } from "../utils"
import { envConfig } from "../config/zendeskConfig"
import { validateAndBuildConfigObj } from "./zendeskUtil"

export const initZendesk = async (config, tokens) => {
  const configObj = await validateAndBuildConfigObj(config, tokens);
  //console.log(JSON.stringify(configObj));
  const webWidget = getWebWidgetSetting(configObj);
  window.zESettings = {
    webWidget: webWidget
  }
  if (!document.getElementById("ze-snippet")) {
    let jsUrl;
    if(configObj.Env === "sandbox") {
      jsUrl = envConfig.sandbox.zendeskjsUrl;
    } else if (configObj.Env === "uat") {
      jsUrl = envConfig.uat.zendeskjsUrl;
    } else {
      jsUrl = envConfig.production.zendeskjsUrl;
    }
    loadSnippet(configObj, jsUrl);
  }
}

export const updateZendesk = async (config, tokens) => {
  const configObj = await validateAndBuildConfigObj(config, tokens);
  const webWidget = getWebWidgetSetting(configObj);
  zE("webWidget", "updateSettings", {
    webWidget: webWidget,
  })
  //reauthenticate after update
  zE(function () {
    zE("webWidget", "helpCenter:reauthenticate")
  })
}

export const openFeedback = () => {
  window.zESettings.webWidget.helpCenter.suppress = true;
  //if(window.zESettings.webWidget.chat) {
  //  window.zESettings.webWidget.chat.suppress = true;
  //}
  
  //const ticketFormId = getDefaultTicketFormId();  //get default ticket form id from environment
  //window.zESettings.webWidget.contactForm.ticketForms = [{ id: ticketFormId }];

  zE("webWidget", "updateSettings", window.zESettings.webWidget);
  zE("webWidget", "open");
  //hideCustomTicketFields();
}

export const openArticles = () => {
  window.zESettings.webWidget.helpCenter.suppress = false;
  //if(window.zESettings.webWidget.chat) {
  //  window.zESettings.webWidget.chat.suppress = true;
  //}

  //const ticketFormId = getDefaultTicketFormId();  //get default ticket form id from environment
  //window.zESettings.webWidget.contactForm.ticketForms = [{ id: ticketFormId }];

  zE("webWidget", "updateSettings", window.zESettings.webWidget);
  zE("webWidget", "open");
  //hideCustomTicketFields();
}

export const openChat = () => {
  window.zESettings.webWidget.helpCenter.suppress = true;
  if(window.zESettings.webWidget.chat) {
    window.zESettings.webWidget.chat.suppress = false;
  }

  //const offlineFormId = getChatOfflineFormId(); //get chat offline ticket form id from environment
  //window.zESettings.webWidget.contactForm.ticketForms = [{ id: offlineFormId }];

  zE("webWidget", "updateSettings", window.zESettings.webWidget);
  zE("webWidget", "open");
  //hideCustomTicketFields();
}

export const logout = () => {
  zE("webWidget", "logout");
}



export const getZendeskTokens = async (config) => {
  let zendeskTokens = {
    "Chat": "",
    "HelpCenter": ""
  }
  if (config && config.User && config.User.Type && config.IdToken && config.IdToken != "") {
    let chatEnabled = (!config.ChatEnabled) ? false : true;
    let zendeskApiUrl = getZendeskApiBaseUrl(config) + config.User.Type + "?usedefaultuser=y";  //"https://hostname/ignrezendesk/tokens/{type}"
    if(chatEnabled == false) {
      zendeskApiUrl = zendeskApiUrl + "&chatenabled=n";
    }
    //console.log(zendeskApiUrl);
    let headers = { "Content-Type": "application/json", "Authorization": config.IdToken }
    let now = Math.floor(new Date().getTime() / 1000);
    let firstName = config.User.FirstName ? config.User.FirstName : (config.User.Name ? config.User.Name : "");
    let payload = { "Now": now, "Name": firstName + " " + config.User.LastName, "Email": config.User.Email}
    try {
      let res = await fetch(zendeskApiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (res.ok) {
        const data = await res.json();
        if(data) {
          //console.log(data);
          if(data.Chat && data.Chat != "")
            zendeskTokens.Chat = data.Chat;
          if(data.HelpCenter && data.HelpCenter != "")
            zendeskTokens.HelpCenter = data.HelpCenter;
        }
      } else {
        //console.log("res.status: " + res.status)
        window.postMessage({ "IGNR_MESSAGE_ID": "IGNRE-ZENDESK-TOKEN-API", "Code": res.status, "message": res.statusText }, "*");
      }

    } catch (error) {
      //console.log("Error Main");
      console.log(error);
      window.postMessage({ "IGNR_MESSAGE_ID": "IGNRE-ZENDESK-TOKEN-API", "Code": 500, "message": error }, "*");
    }
  }

  return zendeskTokens;
}



const getWebWidgetSetting = (configObj) => {

  let authenticate = {
    jwtFn: function (callback) {
      console.log("Zendesk: Helpcenter Authentication")
      callback(configObj.ZendeskTokens.HelpCenter)
    },
  }

  //const ticketFormId = getDefaultTicketFormId();  //get default ticket form id from environment

  const contactForm = composeContactForm(configObj); //compose contactForm object

  let webWidget = {
    /*position: { horizontal: 'right', vertical: 'top' },
    offset: {
      horizontal: '10px',
      vertical: '70px'
    },
    authenticate: authenticate,
    contactOptions: {
      enabled: false,
      contactButton: { '*': 'Live Chat' },
      contactFormLabel: { '*': 'Leave us a message' },
      chatLabelOnline: { '*': 'Live Chat' },
      chatLabelOffline: { '*': 'Chat is unavailable' }
    },*/
    contactForm: contactForm,
    helpCenter: {
      originalArticleButton: configObj.originalArticleButton,
      suppress: true,
      title: {
        '*': 'FAQ'
      },
      searchPlaceholder: {
          '*': 'Search our Articles'
      },
      messageButton: {
          '*': 'Contact Us'
      },
      chatButton: {
          '*': 'Chat with us'
      }
    },
    launcher: {
      mobile: {
        labelVisible: false,
      },
      chatLabel: {
        '*': 'Chat'
      },
      label: {
        '*': 'Contact Us'
      },
    }
  }

  if(configObj.ZendeskTokens.HelpCenter !== "") {
    webWidget.authenticate = authenticate
  }
  if(configObj.filter && (configObj.filter.category || configObj.filter.label_names || configObj.filter.section)) {
    webWidget.helpCenter.filter = configObj.filter;
  }

  if (!!configObj.ChatEnabled && configObj.ZendeskTokens.Chat !== "") {
    webWidget.authenticate.chat = {
      jwtFn: function (callback) {
        console.log("Zendesk: Chat Authentication")
        //getZendeskChatToken(callback, userObj);
        callback(configObj.ZendeskTokens.Chat)
      }
    }
    webWidget.chat = {
      suppress: false,
      connectOnPageLoad: true,
      notifications: {
        mobile: {
          disable: true,
        }
      }
    }
  } 
  console.log("ChatEnabled : " + configObj.ChatEnabled)
  if (configObj.ChatEnabled === false) {
    webWidget.chat = {
      suppress: true
    }
  }

  return webWidget;
}

const composeContactForm = (configObj) => {
  let contactForm = {
    attachments: false,
    title: {
      '*': 'Contact us for support'
    },
    fields: [
      { id: "name", prefill: { "*": configObj.User.Name } },
      { id: "email", prefill: { "*": configObj.User.Email } },
    ]
  }
  /*
  if(configObj.TicketForm.Id) {
    let ticketFields = [];
    let fields = configObj.TicketForm.Fields;
    let Value;
    let Hidden;
    fields.forEach((item) => { 
      let field = item;
      if(field && field.Id) {
        Value = field.Value ? field.Value : "";
        Hidden = field.Hidden && field.Hidden == true ? true : false;
        ticketFields.push(
          {"id": field.Id, "prefill": {'*': Value}, "Hidden": Hidden}
        );
      }
    });
    contactForm.ticketForms = [{ "id": configObj.TicketForm.Id, "fields": ticketFields }]
  }
  */
  if(configObj.TicketFormName) {
    let ticketFormConfig;
    if(configObj.Env === "sandbox") {
      ticketFormConfig = envConfig.sandbox[configObj.TicketFormName];
    } else if(configObj.Env === "uat") {
      ticketFormConfig = envConfig.uat[configObj.TicketFormName];
    } else {
      ticketFormConfig = envConfig.production[configObj.TicketFormName];
    }
    
    let ticketFields = [];
    let fields = ticketFormConfig.Fields;
    let Value;
    let HiddenField = configObj.ZendeskTokens.HelpCenter === "" ? "HiddenForAnonymous" : "HiddenForSignedIn";
    let hidden;
    fields.forEach((item) => { 
      let field = item;
      if(field && field.Id && field.Name) {
        Value = configObj.User[field.Name] ? configObj.User[field.Name] : "";
        hidden = field[HiddenField] && field[HiddenField] === true ? true : false;
        ticketFields.push(
          {"id": field.Id, "prefill": {'*': Value}, "Hidden": hidden}
        );
      }
    });
    if(ticketFormConfig.Id) {
      contactForm.ticketForms = [{ "id": ticketFormConfig.Id, "fields": ticketFields }]
    }
  }
  return contactForm;

}

const getZendeskApiBaseUrl = (config) => {
  let apiUrl;
  //console.log(envConfig);
  if(config && config.Env && config.Env.toLowerCase() === "sandbox") {
    apiUrl = envConfig.sandbox.tokenapi;
  } else if(config && config.Env && config.Env.toLowerCase() === "uat") {
    apiUrl = envConfig.uat.tokenapi;
  } else {
    apiUrl = envConfig.production.tokenapi;
  }
  return apiUrl;
}

const hideCustomTicketFields = () => {
  let fields = window.zESettings.webWidget.contactForm.ticketForms[0].fields;
  let zendeskIframe = document.getElementById("webWidget").contentWindow;
  if (zendeskIframe) {
    fields.forEach((item) => {
      let field = item;
      if (field.Hidden && field.Hidden === true) {
        let element =
          zendeskIframe.document.getElementsByName("key:" + item.id)[0]
        if (element) {
          element.parentNode.style.display = "none"
        }
      }
    });
  } 
}

const loadSnippet = (configObj, jsUrl) => {
  loadScript(
    "ze-snippet",
    jsUrl,
    () => {
      //console.log("script load finished")
      zE(() => {
        //console.log("zE ready fired")
        //enable #zendesk-help-button
        /*const zendeskHelpButton = document.getElementById("zendesk-help-button")
        if (zendeskHelpButton) {
          removeClass(zendeskHelpButton, "zendesk-help-button-hide")
        }*/

        if(configObj.DefaultSearchSuggestion && configObj.DefaultSearchSuggestion !== "") {
          console.log("Zendesk: Setting DefaultSearchSuggestion");
          zE.setHelpCenterSuggestions({ search: configObj.DefaultSearchSuggestion });
        } else {
          console.log("Zendesk: No DefaultSearchSuggestion Available");
        }        
        zE("webWidget:on", "close", function () {
          console.log("Zendesk: webWidget close fired!");
          window.zESettings.webWidget.helpCenter.suppress = true;
          zE("webWidget", "updateSettings", window.zESettings.webWidget);
        })
        zE("webWidget:on", "open", function () {
          console.log("Zendesk: webWidget open fired!");
        })
        zE('webWidget:on', 'userEvent', function (event) {
          //console.log(event);
          //console.log(event.category + ", " + event.action + "," + event.properties);
          //console.log(event.category , event.action, event.properties);
          if(event.action === "Contact Form Shown") {
            hideCustomTicketFields();
          }
        });
        zE('webWidget:on', 'chat:connected', function() {
          console.log('successfully connected to Zendesk Chat!');
        });
        zE('webWidget:on', 'chat:start', function() {
          console.log('successfully started a Zendesk Chat!');
        });
        //$zopim(() => {
        //  console.log("Zopim ready fired")
        //})
      })
    }
  )
}

const loadScript = (id, url, callback) => {
  const script = document.createElement("script")
  script.type = "text/javascript"
  script.id = id
  script.src = url

  // Several events for cross browser compatibility.
  script.onreadystatechange = callback
  script.onload = callback

  // Appending the element will trigger the script load
  document.head.appendChild(script)
}


export const validateAndBuildConfigObj = async (config, tokens) => {
    //console.log("validateAndBuildConfigObj tokens:" + JSON.stringify(tokens));
    let idToken = "";
    let zendeskHelpcenterToken = "";
    let zendeskChatToken = "";
    let zendeskUserName = "";
    let firstName = "";
    let lastName = "";
    let zendeskUserEmail = "";
    let phone = "";
    let type = "";
    let chatEnabled = true;
    let Env = "production";
    let ticketFormName = "FirstAm IgniteRE";
    let defaultSearchSuggestion = "";
    let filter = null;
    let originalArticleButton = false;
    //let TicketFormId = "";
    //let TicketFields = [];
    if (config) {
      idToken = config.IdToken ? config.IdToken.trim() : ""
      zendeskHelpcenterToken = (tokens && tokens.HelpCenter) ?  tokens.HelpCenter : "";
      zendeskChatToken = (tokens && tokens.Chat) ?  tokens.Chat : "";
      if(config.ChatEnabled === false) {
        chatEnabled = false;
      }
      if(config.Env && config.Env.toLowerCase() === "sandbox") {
        Env = "sandbox";
      } else if(config.Env && config.Env.toLowerCase() === "uat") {
        Env = "uat";
      }
      let zendeskUserObj = config.User ? config.User : null;
      if (zendeskUserObj) {
        firstName = zendeskUserObj.FirstName ? zendeskUserObj.FirstName : (zendeskUserObj.Name ? zendeskUserObj.Name : "")
        lastName = zendeskUserObj.LastName ? zendeskUserObj.LastName : ""
        //zendeskUserName = zendeskUserObj.Name ? zendeskUserObj.Name : ""
        zendeskUserName = (firstName + " " + lastName).trim();
        zendeskUserEmail = zendeskUserObj.Email ? zendeskUserObj.Email : ""
        phone = zendeskUserObj.Phone ? zendeskUserObj.Phone : ""
        type = zendeskUserObj.Type ? zendeskUserObj.Type : ""
      }

      ticketFormName = config.TicketFormName ? config.TicketFormName : "FirstAm IgniteRE"
      defaultSearchSuggestion = config.DefaultSearchSuggestion ? config.DefaultSearchSuggestion : "";

      filter =  config.Filter ? config.Filter : null;

      if(config.OriginalArticleButton && config.OriginalArticleButton == true) {
        originalArticleButton = true;
      }

      /*if(config.TicketForm) {
        if(config.TicketForm.Id) {
          TicketFormId = config.TicketForm.Id
          if(config.TicketForm.Fields) {
            let fields = config.TicketForm.Fields;
            fields.forEach((item) => { 
              let field = item;
              if(field && field.Id) {
                let Value = field.Value ? field.Value : "";
                let Hidden = field.Hidden && field.Hidden === true ? true : false;
                TicketFields.push(
                  {"Id": field.Id, "Value": Value, "Hidden": Hidden}
                );
              }
            });
          }
          
        }
        
      }*/
    }

    let configObj = {
      "IdToken": idToken,
      "ZendeskTokens": {
        "HelpCenter": zendeskHelpcenterToken,
        "Chat": zendeskChatToken
      },
      "User": {},
      "ChatEnabled": chatEnabled,
      "Env": Env,
      "TicketFormName": ticketFormName,
      /*"TicketForm": {
          "Id": TicketFormId,
          "Fields": TicketFields
      }*/
      "originalArticleButton": originalArticleButton,
      "DefaultSearchSuggestion": defaultSearchSuggestion,
      "filter": filter
    }

    let userObj = config && config.User ? config.User : null;
    if(userObj) {
      configObj.User = userObj;
    }
    configObj.User["Name"] = zendeskUserName;
    configObj.User["FirstName"] = firstName;
    configObj.User["LastName"] = lastName;
    configObj.User["Email"] = zendeskUserEmail;
    configObj.User["Phone"] = phone;
    configObj.User["Type"] = type;


    //if(zendeskHelpcenterToken === "" && zendeskChatToken === "") {
    //  configObj = await getZendeskAllTokens(userObj);
    //}
    return configObj;
  }
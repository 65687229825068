import sidebarItem from "../sidebarItem"
import sidebarItembottom from "../sidebarItembottom"
import { sideItemClass } from "../constants"

import iconArrowLeft from "../assets/iconArrowLeft"
import {
  parentsWithMatchingSelector,
  hasClass,
  toggleClass,
  addClass,
  removeClass
} from "../utils"

import "./index.scss"

const attachItemClicks = nav => {
  const lis = new Array(...nav.getElementsByClassName(sideItemClass))

  lis.forEach(li => {
    if (li.children[0].tagName === "DIV") {
      li.onclick = event => {
        const subnavCloseClass = ".subnav__mobile-header-wrap"
        const targetIsWithinMobileSubnavCloser =
          parentsWithMatchingSelector(event.target, subnavCloseClass).length > 0

        if (
          targetIsWithinMobileSubnavCloser ||
          hasClass(event.target, subnavCloseClass)
        ) {
          return
        }

        const openClass = `${sideItemClass}--open`

        // Close any other open subnavs
        new Array(...document.getElementsByClassName(openClass)).forEach(el => {
          removeClass(el, openClass)
        })

        // And then open this one
        addClass(li, openClass)

        // Disable sidebar scroll when subnav is clicked and scroll the div to top
        document.getElementsByClassName('sidebar')[0].classList.toggle('no-scroll')
        document.getElementsByClassName('sidebar')[0].scrollTop = 0;
      }
    }
  })
}

const addSalesRep = (salesRep) => {
  if (salesRep?.name && salesRep?.imageUrl && salesRep.name.indexOf("First American") === -1) { // dont show default profile
    return `<img
              class="sidebar__rep-img"
              src="${salesRep.imageUrl}"
              alt="${salesRep.name}"
            >
            <div class="sidebar__rep-info">
              <p class="sidebar__rep-name">${salesRep.name}</p>
              <p class="sidebar__rep-role">My Sales Rep</p>
            </div>`;
  } else {
    return ``;
  }
}

export const sidebarEl = (sidebarItems, topItems, salesRep) => {
  const sidebar = document.createElement("section")
  addClass(sidebar, "sidebar")
  sidebar.innerHTML = `
      <section class="sidebar__top">
        <ul class="sidebar__list">
          ${sidebarItems.map(item => sidebarItem(item, sideItemClass)).join("")}
        </ul>
      </section>
      <section class="sidebar-bottom-mobile">
        <ul class="sidebar-bottom-mobile__list">
          ${topItems.filter(item => item.mobileOnly).map(item => sidebarItembottom(item, sideItemClass)).join("")}
        </ul>
      </section>
      <section class="sidebar__bottom">
        <article id="sidebar__salesrep" class="sidebar__rep">
            ${addSalesRep(salesRep)}
        </article>
        <article class="sidebar__resize">
          ${iconArrowLeft()}
        </article>
      </section>
  `

  const arrowElement = sidebar.getElementsByClassName("sidebar__resize")[0]
  arrowElement.onclick = () => {
    toggleClass(sidebar, "sidebar--compressed")
    //document.getElementsByTagName( 'body' )[0].classList.toggle('sidebar-compressed')
    document.getElementsByClassName('topnav')[0].classList.toggle('sidebar--compressed-nav')
    document.getElementsByClassName('main')[0].classList.toggle('sidebar--compressed-main')

  }

  attachItemClicks(sidebar)
  return sidebar
}

export const renderSidebarItems = (sidebarItems) => {
  const sidebarTop = document.getElementsByClassName("sidebar__top")[0];
  if (sidebarTop) {
    sidebarTop.innerHTML = `
      <ul class="sidebar__list">
        ${sidebarItems.map(item => sidebarItem(item, sideItemClass)).join("")}
      </ul>`
  }
}

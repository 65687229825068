import { sideItemClass } from "../constants"
import iconArrowLeft from "../assets/iconArrowLeft"

import "./index.scss"

const linkItem = ({ url, text, display }) => `
<a class="sidebar-bottom-mobile__link" href="${url}">
  <div class="${display}"> </div>
  <span class="sidebar-bottom-mobile__link-title">${text}</span>
</a>
`

const expandableItem = ({ text, display, subItems }) => `
  <div class="sidebar-bottom-mobile__link">
    <div class="${display}"></div>
    <span class="sidebar-bottom-mobile__link-title"">${text}</span>
    <ul class="subnav">
      <div class="subnav__mobile-header-wrap">
        ${iconArrowLeft()}
        <h4 class="subnav__mobile-header">${text}</h4>
      </div>
      <span class="subnav__caret"></span>
      ${subItems
        .map(
          sub =>
            `<li class="subnav__list-item">
            <a class="subnav__list-link" href="${sub.url}">${sub.text}</a>
          </li>`
        )
        .join("")}
    </ul>
  </div>
`

// Each sidebar item can be rendered as a link or a div which expands to show subItems.
export default item => {
  const currentUrl = location.href

  const isActive = item.url
    ? item.url.includes(currentUrl)
    : item.subItems.some(item => item.url.includes(currentUrl))

  const activeClass = isActive ? ` ${sideItemClass}--active` : ""

  const sideBarId = item.text ? "sidebar_" + (item.text).replace(/ /g, '').toLowerCase() + "_id" : "";

  return `
    <li class="sidebar-bottom-mobile__list-item ${`${sideItemClass}${activeClass}`}" id="${sideBarId}" >
      ${item.url ? linkItem(item) : expandableItem(item)}
    </li>
  `
}

//import "@babel/polyfill"
import "core-js/web";
import "regenerator-runtime/runtime";

import "./polyfills/prepend"

import { sideItemClass } from "./constants"
import { sidebarEl, renderSidebarItems } from "./sidebar"
import { footerEl } from "./footer"
import { topNavEl, topnavDropdownClass, renderUserItems } from "./topNav"
import {
  parentsWithMatchingSelector,
  validateAndGetContainers,
  ensureSubnavsDontExceedWindowBounds,
  toggleClass,
  addClass,
  removeClass
} from "./utils"

import "./styles/index.scss"
import "./zendesk/index.scss"

import { initZendesk, updateZendesk, getZendeskTokens, openFeedback, openArticles, openChat, logout } from "./zendesk"

import { initCCPA } from "./ccpa";
import { initSpeedtest, downloadImage } from "./connectivitybanner";

import { initFullstory } from "./fullstory";


/**
 * Bootstraps the nav, inserting it into the parent page's DOM,
 * and applying necessary styles to the nav and content to get them to
 * appear correctly.
 *
 * @param {String} parentId - Unique identifier for the parent container. For the below example, 'parent-container'
 * The parent container must have children in this exact structure:
 *   <div id="parent-container">
 *    <div> // Main Row
 *      <div> // Content wrap
 *        <div></div> // Application goes here
 *      </div>
 *    </div>
 *   </div>
 *
 * @param {Object} data - Object with the following keys:
 *   - @param {Object[]} sidebarItems - Array of items to display in sidebar. Has:
 *     - @param {String} text - Text of item to display.
 *     - @param {String} iconUrl - URL to icon to display next to item.
 *     - @param {String} [url] - Optional URL to link to on click. Required if no subItems provided.
 *     - @param {Object[]} [subItems] - Optional subItems to render on click. Required if no url provided.
 *       - @param {String} text - Text to display on subItem.
 *       - @param {String} url - URL to link to on click.
 *
 *   - @param {Object[]} topItems - Non-user items to render in top nav.
 *     - @param {String} text - Text of item to display on icon hover.
 *     - @param {String} iconUrl - Icon to display.
 *     - @param {String} url - Destination to go on click.
 *     - @param {Boolean} mobileOnly - Whether the item only shows up on small screens
 *
 *   - @param {Object} salesRep - The authenticated user's sales rep.
 *     - @param {String} name - Name of the rep.
 *     - @param {String} imageUrl - Image of the rep.
 *   - @param {Object} user - The authenticated user.
 *     - @param {String} firstName - First name.
 *     - @param {String} lastName - Last name.
 *
 *   - @param {Object} footerItems - Items for the footer
 *     - @param {Object[]} socialLinks - Social icons to display, along with URLs
 *       - @param {String} url Destination for the icon link
 *       - @param {String} iconUrl Source of the icon URL
 *       - @param {String} alt Alt text of icon image
 *     - @param {Object[]} leftLinks - Links to display on left side of footer
 *       - @param {String} url Destination for the icon link
 *       - @param {String} text Visible text
 *       - @param {String} newWindow use true to open the link in a new window/tab
 * @return {Promise} The resolved promise function fires when bootstrapping is complete, and is passed the parent container element.
 */
const bootstrap = (parentId, data) => {
  const {
    sidebarItems = [],
    topItems = [],
    salesRep = {},
    user = {},
    footerItems = {}
  } = data;
  return new Promise(resolve => {
    const { parent, app, wrap, main } = validateAndGetContainers(parentId);

    const sidebar = sidebarEl(sidebarItems, topItems, salesRep);
    parent.prepend(sidebar);
    parent.prepend(topNavEl(topItems, user));

    const backdrop = document.createElement("div");
    backdrop.classList.add("backdrop");
    const footer = footerEl(footerItems);
    wrap.appendChild(footer);
    wrap.appendChild(backdrop);

    // Apply consistent class to content element
    addClass(parent, "parent");
    addClass(main, "main");
    addClass(wrap, "content-wrap");
    addClass(app, "app");

    const hamburger = document.getElementsByClassName(
      "topnav__icon-hamburger"
    )[0];
    const close = document.getElementsByClassName("topnav__icon-close")[0]
    const closeSubnav = new Array(...document.getElementsByClassName(
      "subnav__mobile-header-wrap"
    ));
    const meatballdropDown = document.getElementsByClassName(
      "meatball-dropdown"
    )[0];
    ;[hamburger, close].forEach(element => {
      element.onclick = () => {
        toggleClass(hamburger, "is-hidden")
        toggleClass(close, "is-visible")
        toggleClass(sidebar, "is-visible")
        toggleClass(wrap, "backdrop-show")
        const meatballOpen = document.getElementsByClassName(
          "meatball-dropdown--open"
        )[0];
        if (meatballOpen) {
          toggleClass(meatballdropDown, "meatball-dropdown--open")
        }

        //This will enable the scroll of sidebar when hamburger menu is clicked
        if (document.getElementsByClassName('no-scroll')[0]) {
          removeClass(document.getElementsByClassName('sidebar')[0], `no-scroll`)
        }
      }
    })
    const containerbackdrop = document.getElementsByClassName("backdrop")[0];
    containerbackdrop.onclick = () => {
      toggleClass(hamburger, "is-hidden")
      toggleClass(close, "is-visible")
      toggleClass(sidebar, "is-visible")
      toggleClass(wrap, "backdrop-show")
      //This will enable the scroll of sidebar when clicked on backdrop
      if (document.getElementsByClassName('no-scroll')[0]) {
        removeClass(document.getElementsByClassName('sidebar')[0], `no-scroll`)
      }
    }
    if (closeSubnav.length > 0) {
      closeSubnav.forEach(item => {
        item.onclick = event => {
          const parents = parentsWithMatchingSelector(
            event.target,
            `.${sideItemClass}`
          )
          removeClass(parents[0], `${sideItemClass}--open`)
          //This will enable the scroll of sidebar when subnav is closed
          removeClass(document.getElementsByClassName('sidebar')[0], `no-scroll`)
        }
      })
    }

    ensureSubnavsDontExceedWindowBounds(sidebar)
    const meatBallMenu = "meatball-dropdown"

    // Close dropdowns/subnavs if open, on click anywhere outside of that item
    document.body.onclick = ({ target }) => {
      ;[sideItemClass, topnavDropdownClass, meatBallMenu].forEach(className => {
        const openItems = new Array(
          ...document.getElementsByClassName(`${className}--open`)
        )
        // Only bother if any open
        if (openItems.length === 0) return

        const openParents = parentsWithMatchingSelector(
          target,
          `.${className}--open`
        )

        // Don't close if clicking on open element
        if (openParents.length > 0) return

        openItems.forEach(item => {
          removeClass(item, `${className}--open`)
        })

        if (document.getElementsByClassName('no-scroll')[0]) {
          removeClass(document.getElementsByClassName('sidebar')[0], `no-scroll`)
        }

      })
    }

    resolve(parent)
  })
}

const renderSalesRep = (salesRep) => {
  if (salesRep?.name && salesRep?.imageUrl && salesRep.name.indexOf("First American") === -1) { // dont show default profile
    const sideBarSalesRep = document.getElementById("sidebar__salesrep");
    sideBarSalesRep.innerHTML = `<img
                                  class="sidebar__rep-img"
                                  src="${salesRep.imageUrl}"
                                  alt="${salesRep.name}"
                                >
                                <div class="sidebar__rep-info">
                                  <p class="sidebar__rep-name">${salesRep.name}</p>
                                  <p class="sidebar__rep-role">My Sales Rep</p>
                                </div>`;
  }
}


window.FAMNav = { bootstrap, renderSalesRep, renderSidebarItems, renderUserItems }
window.FAMZendesk = { initZendesk, updateZendesk, getZendeskTokens, openFeedback, openArticles, openChat, logout }
window.FAMCCPA = { init: initCCPA }
window.IGRECONNECTIVITY = { init: initSpeedtest, checkConnectivity: downloadImage }
window.IGREFullStory = { init: initFullstory }


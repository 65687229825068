export const envConfig = 
{
	"production": {
		"tokenapi": "https://ignitere.firstam.com/api/igrezendesk/tokens/",
		"zendeskjsUrl": "https://static.zdassets.com/ekr/snippet.js?key=c90665a6-1f33-4432-9963-4b0f2c3b3127",
		"FirstAm IgniteRE": {
			"Id": "1500000021421",
			"Fields": [
                /*{
					"Name": "Name",
					"Id": "1500006908261",
					"HiddenForSignedIn": true,
                    "HiddenForAnonymous": true
				},                
                {
					"Name": "Phone",
					"Id": "1500006475001",
					"HiddenForSignedIn": true,
                    "HiddenForAnonymous": false
				}*/
			]
		},
		"Transactions portal": {
			"Id": "4766004059667",
			"Fields": []
		}
	}, 
	"sandbox": {
		"tokenapi": "https://dev.ignitere.firstam.com/api/igrezendesk/tokens/",
		"zendeskjsUrl": "https://static.zdassets.com/ekr/snippet.js?key=600632df-05db-4dc2-8d58-35cac60e0508",
		"FirstAm IgniteRE": {
			"Id": "1500001122501",
			"Fields": [
               /* {
					"Name": "Name",
					"Id": "1500006908261",
					"HiddenForSignedIn": true,
                    "HiddenForAnonymous": true
				},                
                {
					"Name": "Phone",
					"Id": "1500006475001",
					"HiddenForSignedIn": true,
                    "HiddenForAnonymous": false
				}*/
			]
		},
		"Transactions portal": {
			"Id": "5022140510995",
			"Fields": []
		}
	}, 
	"uat": {
		"tokenapi": "https://stage.ignitere.firstam.com/api/igrezendesk/tokens/",
		"zendeskjsUrl": "https://static.zdassets.com/ekr/snippet.js?key=d74f6d67-553d-41fd-9972-0e5b991f3510",
		"FirstAm IgniteRE": {
			"Id": "1900000143085",
			"Fields": [
               /* {
					"Name": "Name",
					"Id": "1500006908261",
					"HiddenForSignedIn": true,
                    "HiddenForAnonymous": true
				},                
                {
					"Name": "Phone",
					"Id": "1500006475001",
					"HiddenForSignedIn": true,
                    "HiddenForAnonymous": false
				}*/
			]
		},
		"Transactions portal": {
			"Id": "4415794572435",
			"Fields": []
		}
	}
}
export const parentsWithMatchingSelector = (target, selector) => {
  return [...document.querySelectorAll(selector)].filter(
    el => el !== target && el.contains(target)
  )
}

export const findInDocumentOrThrow = (selector, name) => {
  const el =
    document.getElementById(selector.replace("#", "")) ||
    document.getElementsByClassName(selector.replace(".", ""))[0]

  if (!el) {
    throw new Error(
      `Element for ${name} could not be found. Selector: ${selector}`
    )
  }

  return el
}

export const validateAndGetContainers = id => {
  try {
    const parent = document.getElementById(id.replace("#", ""))
    const main = parent.children[0]
    const wrap = main.children[0]
    const app = wrap.children[0]
    return { wrap, app, parent, main }
  } catch (error) {
    console.error(error)
    const criteria = `
    - Parent selector must be an ID
    - Parent must have single child which is main row.
    - Main row must have single child which is content wrapper.
    - Content wrapper must have child which is app
    `
    throw new Error(`Parent selector failed to meet criteria: ${criteria}.`)
  }
}

export const ensureSubnavsDontExceedWindowBounds = sidebar => {
  const subnavs = new Array(...sidebar.getElementsByClassName("subnav"))

  subnavs.forEach(nav => {
    const windowHeight = window.innerHeight
    const rect = nav.getBoundingClientRect()
    const { bottom, top } = rect
    const buffer = 20

    if (bottom > windowHeight - buffer) {
      const difference = bottom - windowHeight + buffer
      nav.style.top = `-${difference}px`
    }

    if (top < buffer) {
      const difference = top - buffer
      nav.style.top = `${difference * -1}px`
    }
  })
}

// The below methods essentially fill in for missing element.classList functionality on versions of IE
export const getClassName = element =>
  element.tagName === "svg" ? element.getAttribute("class") : element.className

export const setClassName = (element, className) => {
  if (element.tagName === "svg") {
    element.setAttribute("class", className)
  } else {
    element.className = className
  }
}

export const classes = element => {
  const cn = getClassName(element)
  return cn.split(" ").filter(s => s)
}

export const hasClass = (element, className) =>
  classes(element).includes(className)

export const addClass = (element, className) => {
  if (hasClass(element, className)) return
  setClassName(element, `${getClassName(element)} ${className}`)
}

export const removeClass = (element, className) => {
  if (!hasClass(element, className)) return
  setClassName(
    element,
    classes(element)
      .filter(c => c !== className)
      .join(" ")
  )
}

export const toggleClass = (element, className) => {
  const toggle = hasClass(element, className) ? removeClass : addClass
  toggle(element, className)
}
